/** @jsxImportSource theme-ui */
import { jsx, Box, Container, Flex } from "theme-ui";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { DefaultLayout as Layout } from "../Layout/Default";
import { SEO } from "../components/SEO";
import Hero from "../components/Hero/BasicHero";

const PolicyPage = (props: any) => {
  const [pdfUrl, setPdfUrl] = useState("");
  useEffect(() => {
    if (props.pageContext.pdfName && typeof window != undefined) {
      setPdfUrl(props.pageContext.pdfName.file.url);
    }
    if (pdfUrl != "") {
      window.open(pdfUrl);
    }
  }, [pdfUrl]);
  return (
    <Layout
      disclaimers={props.pageContext.disclaimers}
      showMasterDisclaimer={props.pageContext.showMasterDisclaimer}
      showCustomDisclaimers={props.pageContext.showCustomDisclaimers}
    >
      <SEO
        title={props.pageContext.seo.title || ""}
        description={props.pageContext.seo.description || ""}
      />
      <Hero
        basicHero={{
          ...props.pageContext.hero,
        }}
      />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1020px",
          mx: "auto",
          px: "20px",
          py: 5,
          "& p, & ul, & ol": {
            mb: 4,
          },
          "& h2": {
            mb: 2,
            fontSize: ["30px", null, "36px", null],
            letterSpacing: [".3px", null, ".36px", null],
            lineHeight: ["38px", null, "44px", null],
            color: "brand.navy",
          },
          "& h3": {
            color: "white",
            fontSize: ["30px", null, "36px", null],
            letterSpacing: [".3px", null, ".36px", null],
            lineHeight: ["38px", null, "44px", null],
            backgroundColor: "brand.tealDark",
            px: 2,
            pt: "10px",
            pb: 2,
            mb: 3,
          },
          "& ul": {
            listStyleType: "disc",
            pl: 4,
          },
          "& ol": {
            listStyleType: "decimal",
            pl: 4,
          },
          "& ul li, & ol li": {
            mb: 1,
            fontSize: "18px",
          },
        }}
      >
        {props.pageContext.content ? (
          <MDXRenderer>{props.pageContext.content.childMdx.body}</MDXRenderer>
        ) : (
          <p>
            There was a problem while trying to fetch this policy page. Please
            try again or contact us <a href="/contact-us/">here</a>.
          </p>
        )}
      </div>
    </Layout>
  );
};

export default PolicyPage;
